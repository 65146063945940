<template>
  <section>
    <!-- Titulo de la vista -->
    <v-breadcrumbs v-if="!haveRole('ROLE_PROVEEDOR')">
      <template v-for="(item, index) in breadcrumbs">
        <v-breadcrumbs-item
          :key="index"
          :href="item.href"
          :disabled="item.disabled"
          v-if="item.show"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
        
      </template>
    </v-breadcrumbs>

    <!-- Tabs para agregar accionistas de forma individual y por medio de archivo excel -->
    <v-row v-if="haveRole('ROLE_PROVEEDOR')">
      <v-col cols="12">
        <v-tabs background-color="bgMinsal">
          <v-tab class="secondary-text"
            ><v-icon>mdi-account </v-icon> Agregar accionista</v-tab
          >
          <!-- <v-tab class="secondary-text"
            ><v-icon>mdi-file-upload-outline</v-icon>Cargar Nómina</v-tab
          > -->

          <v-tab-item>
            <agregar-accionista-v2-component></agregar-accionista-v2-component>
          </v-tab-item>
          <v-tab-item>
            <formNomina></formNomina>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <!-- Tabla accionistas -->
    <v-row>
      <v-col cols="12">
        <tabla-accionistas></tabla-accionistas>
      </v-col>
    </v-row>
    
    <!-- Botón de regresar -->
    <v-row>
      <v-col cols="12">
        <v-btn class="mt-5" color="primary" outlined @click="regresar">
          Regresar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import TablaAccionistas from './components/TablaAccionistasComponent.vue'
import AgregarAccionistaV2Component from './components/AgregarAccionistaV2Component.vue'
import formNomina from './components/FormNominaComponent.vue'
export default {
  name: 'agregarAccionistaView2',
  components: {
    AgregarAccionistaV2Component,
    TablaAccionistas,
    formNomina
  },
  data: () => ({}),
  computed: {
    ...mapState('proveedores', ['provider']),
    breadcrumbs () {
      return [
        {
          show: this.haveRole("ROLE_SUPERVISOR") ? true : false,
          text: 'RUPES /',
          disabled: false,
          href: `/rupes`
        },
        {
          show: true,
          text: this.provider?.nombre,
          disabled: false,
          href: `/proveedor/${this.$route.params.idProveedor}`
        },
        {
          show: true,
          text: '/ Agregar Accionistas y representante legal',
          disabled: true
        }
      ]
    }
  },
  methods: {
    ...mapActions('proveedores', ['getProvider']),
    regresar () {
      // Navegar a la ruta anterior
      this.$router.back()
    }
  },
  async created () {
    await this.getProvider(Number(this.$route.params.idProveedor))
  }
}
</script>
<style lang=""></style>
