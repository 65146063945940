<template>
  <section fluid class="bgMinsal">
    <v-row class="mt-6">
      <v-col cols="12" lg="6" class="pa-3">
        <div>
          <v-alert
            v-model="alert"
            color="secondary"
            border="left"
            elevation="2"
            colored-border
            icon="mdi-information-outline"
          >
            <v-row>
              <v-col cols="11" class="secondary--text">
                Si desea cargar la nómina de accionistas deberá descargar y
                utilizar el siguiente formato
              </v-col>

              <v-col>
                <v-icon @click="downloadFormat" color="secondary"
                  >mdi-file-download-outline</v-icon
                >
              </v-col>
            </v-row>
          </v-alert>
        </div>
      </v-col>

      <v-col cols="12">
        <v-file-input
          v-model="file"
          outlined
          :prepend-icon="null"
          accept="text/csv"
          prepend-inner-icon="mdi-paperclip"
          label="Nómina accionistas - Cargar archivo CSV"
          style="width: 600px"
        ></v-file-input>
      </v-col>
      <v-col cols="12">
        <v-btn
          @click="cargarNomina"
          color="secondary white--text px-sm-8"
          :loading="cargandoNomina"
          >Cargar nómina</v-btn
        >
      </v-col>
    </v-row>
  </section>
</template>

<script>
import tablaAccionistas from "./TablaAccionistasComponent.vue";
import ProveedoresServices from "../../../services/Proveedores.services";
import { mapActions } from "vuex";

export default {
  name: "FormNominaComponent",
  components: { tablaAccionistas },
  data() {
    return {
      file: null,
      alert: true,
      cargandoNomina: false,
    };
  },
  methods: {
    ...mapActions("shareholders", ["getShareholders"]),
    async downloadFormat() {
      const response = await ProveedoresServices.downloadExcelDocumentFormat();

      // Descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "formato_nomina_accionistas.csv");
      document.body.appendChild(link);
      link.click();
    },
    async cargarNomina() {
      this.cargandoNomina = true;
      if (this.file != null && this.file != undefined) {
        const formData = new FormData();
        formData.append("archivo", this.file);
        const response = await ProveedoresServices.cargarNominaAccionistas(
          formData
        );

        if (response.status == 201) {
          this.temporalAlert({
            show: true,
            message: "Nómina cargada correctamente",
            type: "success",
          });

          // Recrear tabla
          this.getShareholders();
        }
        // Limpiar el input

        this.cargandoNomina = false;
        this.file = null;
      } else {
        this.cargandoNomina = false;
        this.temporalAlert({
          show: true,
          message: "Debe seleccionar un archivo",
          type: "error",
        });
      }
    },
  },
};
</script>
